
import {
  IonPage,
  alertController,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonContent,
  IonLabel,
  IonInput,
  IonList,
  IonIcon
} from "@ionic/vue";
import axios from "axios";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/components/FormGroup.vue";
import Button from "@/components/Button.vue";
import mixin from "@/mixin";
import ApiService from "@/services/api.service";
import { AppVersion } from "@ionic-native/app-version";
import { loadingController } from "@ionic/vue";
import { Browser } from "@capacitor/browser";
import { App } from "@capacitor/app";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio";

export default defineComponent({
  name: "Login",
  components: {
    IonPage,
    FormGroup,
    Button,
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonIcon,
    IonContent,
    IonSelect,
    IonSelectOption
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const showPassword = false;

    return {
      router,
      t,
      showPassword
    };
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      selectedEnvironment: localStorage.getItem("BASE_API") as string,
      appVersion: "",
      availableEnvironments: [],
      switchableEnvironments: process.env?.VUE_APP_MULTI == "true",
      ssoEnabled: process.env?.VUE_APP_SSO_ENABLED == "true",
      faceIdEnabled: false,
    };
  },

  mixins: [mixin],

  watch: {
    selectedEnvironment() {
      ApiService.init(this.selectedEnvironment);
      this.setLogoutTime();
    }
  },

  computed: {
    developmentApiUrl() {
      return process.env.VUE_APP_ROOT_API;
    },

    ...mapGetters("auth", [
      "authenticating",
      "authenticationError",
      "authenticationErrorCode"
    ]),
    ...mapGetters({
      getPermission: "permission/getPermission"
    }),
    passwordIcon(): string {
      return this.showPassword ? eyeOffOutline : eyeOutline;
    },
    greeting() {
      const date = new Date();
      const hour = date.getHours();

      if (hour < 12) {
        return "greetings.morning";
      } else if (hour < 18) {
        return "greetings.afternoon";
      } else {
        return "greetings.evening";
      }
    }
  },
  methods: {
    ...mapActions("auth", ["signIn", "signOut", "signInWithAccessToken"]),
    ...mapActions("permission", ["fetchPermission"]),
    ...mapMutations("auth", ["setCredentials"]),

    async ssoLogin() {
      const url = `${
        process.env.VUE_APP_ROOT_API
      }/sso?callback_url=${encodeURIComponent(process.env.VUE_APP_DEEPLINK_URL + "://auth#code=")}`;

      window.open(url);

      // await Browser.open({
      //   url: url,
      //   presentationStyle: "popover"
      // });
    },

    async addRedirectListener() {
      App.addListener("appUrlOpen", async (data: any) => {
        if (data.url && data.url.indexOf("auth#") != -1) {
          const regEx = /(auth#code=)(.*)/g;
          const code = regEx.exec(data.url);
          if (code && code.length > 1) {
            const accessToken = code[0].replace("auth#code=", "");
            await this.signInWithAccessToken(accessToken);

            this.fetchPermission()
              .then(async () => {
                if (this.getPermission("login_expiry_time_in_hours")) {
                  localStorage.setItem('EXPIRY_TIME', (parseInt(this.getPermission("login_expiry_time_in_hours")) * 60).toString());
                }

                if (
                  this.getPermission("user.type")?.toLowerCase() ===
                  "professional"
                ) {
                  await this.$router.push("/patients");
                } else {
                  window.location.reload();
                }
              })
              .catch(() => {
                alert("Unable to get permission");
                this.signOut();
                window.location.reload();
              });
          }
        }
        await Browser.close();
      });
    },

    async handleLogin() {
      const loading = await loadingController.create({
        message: this.t("auth.loading")
      });

      await loading.present();

      this.signIn(this.form)
        .then(async () => {
          await loading.dismiss();

          localStorage.setItem('username', this.form.username);

          this.form.username = "";
          this.form.password = "";

          this.fetchPermission()
            .then(async () => {
              if (this.getPermission("login_expiry_time_in_hours")) {
                localStorage.setItem('EXPIRY_TIME', (parseInt(this.getPermission("login_expiry_time_in_hours")) * 60).toString());
              }

              if (
                this.getPermission("user.type")?.toLowerCase() ===
                "professional"
              ) {
                await this.$router.push("/patients");
              } else {
                window.location.reload();
              }
            })
            .catch(() => {
              this.signOut();
              window.location.reload();
            });
        })
        .catch(async (err: any) => {
          await loading.dismiss();

          if (err === "Two factor token required") {
            this.setCredentials(this.form);
            await this.$router.push("/auth/2fa");
            return;
          }

          if (err === "Contact Unauthorized") {
            const errorAlert = await alertController.create({
              header: this.t("auth.failedContact"),
              subHeader: this.t("auth.failedTextContact"),
              buttons: ["OK"]
            });
            await errorAlert.present();
          } else {
            const errorAlert = await alertController.create({
              header: this.t("auth.failed"),
              subHeader: this.t("auth.failedText"),
              buttons: ["OK"]
            });
            await errorAlert.present();
          }
        });
    },

    async getAvailableEnvironments() {
      const { data } = await axios.get(
        "https://demo.telerevalidatie.nl/api/v4/app"
      );
      this.availableEnvironments = data;
    },

    setUsername() {
      this.form.username = localStorage.getItem('username') ?? '';
    },

    setLogoutTime() {
      const env = Object.values(this.availableEnvironments).find((a: any) => a.baseUrl === this.selectedEnvironment) as any;


      if (env) {
        localStorage.setItem('EXPIRY_TIME', env.timeoutInMinutes);
      }
    },

    faceIdLogin() {
      FingerprintAIO.isAvailable().then(value => {
        if (value && localStorage.getItem("bio")) {
          this.faceIdEnabled = true;
          FingerprintAIO.show({
            description: "Login"
          }).then(async result => {
            if (result.toLowerCase() === "success" || result.toLowerCase() === "biometric_success") {
              const { data } = await ApiService.post('biometricLogin', {
                token: localStorage.getItem("bio")
              });

              this.signInWithAccessToken(data.access_token).then(() => {
                this.fetchPermission()
                  .then(async () => {
                    if (this.getPermission("login_expiry_time_in_hours")) {
                      localStorage.setItem('EXPIRY_TIME', (parseInt(this.getPermission("login_expiry_time_in_hours")) * 60).toString());
                    }

                    if (
                      this.getPermission("user.type")?.toLowerCase() ===
                      "professional"
                    ) {
                      await this.$router.push("/patients");
                    } else {
                      window.location.reload();
                    }
                  })
                  .catch(() => {
                    this.signOut();
                    window.location.reload();
                  });
              })
            }
          });
        }
      });
    },

    ionViewWillEnter() {
      this.faceIdLogin();
    },
  },

  created() {
    this.addRedirectListener();
    this.setUsername();
    this.getAvailableEnvironments();

    if (!this.switchableEnvironments) {
      this.selectedEnvironment = process.env?.VUE_APP_ROOT_API;
      localStorage.removeItem("BASE_API");
      localStorage.removeItem('EXPIRY_TIME');
    } else {
      this.setLogoutTime();
    }

    AppVersion.getVersionNumber().then((code: string) => {
      this.appVersion = code;

      this.appVersion += ` (build: ${process.env.VUE_APP_DEPLOY_VERSION ?? 1})`;
    });
  }
});
